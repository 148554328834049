import { createSlice } from "@reduxjs/toolkit";
import {
  register,
  logIn,
  logInGoogle,
  logOut,
  refreshUser,
  getUserInfo,
  getMyProfile,
  fetchFaqAndTutorial,
  fetchRoles,
  fetchTail,
  fetchIsFBConversion,
  fetchBalance,
} from "./operations";
import { actionCookies } from "utils/common";

const initialState = {
  user: {},
  myProfile: {},
  roles: {},
  tail: null,
  isFBConversion: undefined,
  error: null,
  token: null,
  isLoggedIn:
    actionCookies("token") || localStorage.getItem("token") ? true : false,
  isRefreshing: false,
  info: { faq: null, tutorial: null },
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserInfo(state, action) {
      state.user.user_info.email_confirmed = action.payload;
    },
    setIsUserVerified(state, action) {
      state.user.user_info.is_verified = action.payload.isVerified;
      state.user.user_info.is_bonus_phone = action.payload.isBonus;
    },
    setUserSpendFirstCredit(state) {
      state.user.user_info.spend_first_credit = 1;
    },
  },
  extraReducers: {
    [register.pending](state) {
      state.error = null;
      state.isLoading = true;
    },
    [register.fulfilled](state, action) {
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.isLoading = false;
    },
    [register.rejected](state, action) {
      state.error = action.payload;
      state.isRefreshing = false;
      state.isLoading = false;
    },

    [logIn.pending](state) {
      state.error = null;
      state.error = null;
      state.isLoading = true;
    },
    [logIn.fulfilled](state, action) {
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.isLoading = false;
    },
    [logIn.rejected](state, action) {
      state.error = action.payload;
      state.isRefreshing = false;
      state.isLoading = false;
    },

    [logInGoogle.fulfilled](state, action) {
      state.token = action.payload.token;
      state.isLoggedIn = true;
    },

    [logOut.fulfilled](state) {
      state.token = null;
      state.isLoggedIn = false;
      state.isLoading = false;
      actionCookies("token", "del");
      actionCookies("fingerprinthash", "del");
      localStorage.removeItem("token");
      localStorage.removeItem("fingerprinthash");
      window.location = "/";
    },

    [refreshUser.pending](state) {
      state.isRefreshing = true;
      state.isLoading = true;
    },
    [refreshUser.fulfilled](state) {
      state.isLoggedIn = true;
      state.isRefreshing = false;
      state.isLoading = false;
    },
    [refreshUser.rejected](state) {
      state.isRefreshing = false;
      state.isLoading = false;
    },

    [getUserInfo.pending](state) {
      state.isLoading = true;
    },
    [getUserInfo.fulfilled](state, action) {
      state.user = action.payload;
      state.isLoading = false;
    },
    [getUserInfo.rejected](state) {
      state.isLoading = false;
    },

    [getMyProfile.pending](state) {
      state.isLoading = true;
    },
    [getMyProfile.fulfilled](state, action) {
      state.myProfile = action.payload;
      state.isLoading = false;
    },
    [getMyProfile.rejected](state) {
      state.isLoading = false;
    },

    [fetchFaqAndTutorial.pending](state) {
      state.isLoading = true;
    },
    [fetchFaqAndTutorial.fulfilled](state, action) {
      state.info = action.payload;
      state.isLoading = false;
    },
    [fetchFaqAndTutorial.rejected](state) {
      state.isLoading = false;
    },

    [fetchRoles.pending](state) {
      state.isLoading = true;
    },
    [fetchRoles.fulfilled](state, action) {
      state.roles = action.payload;
      state.isLoading = false;
    },
    [fetchRoles.rejected](state) {
      state.isLoading = false;
    },

    [fetchTail.pending](state) {
      state.isLoading = true;
    },
    [fetchTail.fulfilled](state, action) {
      state.tail = action.payload;
      state.isLoading = false;
    },
    [fetchTail.rejected](state) {
      state.isLoading = false;
    },

    [fetchIsFBConversion.pending](state) {
      state.isLoading = true;
    },
    [fetchIsFBConversion.fulfilled](state, action) {
      state.isFBConversion = action.payload.status;
      state.isLoading = false;
    },
    [fetchIsFBConversion.rejected](state) {
      state.isLoading = false;
    },

    [fetchBalance.fulfilled](state, action) {
      state.user.user_info.credits = action.payload.credits;
      state.user.user_info.bonus_credits = action.payload.bonus_credits;
      state.user.user_info.promo_credits = action.payload.promo_credits;

      state.myProfile.user_detail.credits = action.payload.credits;
      state.myProfile.user_detail.bonus_credits =
        action.payload.bonus_credits;
      state.myProfile.user_detail.promo_credits =
        action.payload.promo_credits;
    },
  },
});

export const { updateUserInfo, setIsUserVerified, setUserSpendFirstCredit } =
  authSlice.actions;
export const authReducer = authSlice.reducer;
