import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getRequest, postRequest } from "utils/requests";

import { register, logIn, logInGoogle } from "redux/auth/operations";
import { togleGlobalModal, loaderOn, loaderOff } from "redux/interface/slice";
import { selectError } from "redux/auth/selectors";

import useGetCaptchaConfig from "hooks/useGetCaptchaConfig";
import { useDetectedDevice, useAddTail } from "hooks";

import { MIRROR } from "data/mirrorConfig";
import { getUrlParameter, findAge } from "utils/common";

const initialStateForm = {
  name: "",
  email: "",
  password: "",
  bDay: undefined,
  bMonth: undefined,
  bYear: undefined,
  looking: undefined, // default Female
  policy: false,
};

const useMain = () => {
  const [modeLogin, setModeLogin] = useState("create");
  const [valueRegister, setValueRegister] = useState(initialStateForm);
  const [openModalValidate, setOpenModalValidate] = useState(false);
  const [error, setError] = useState(false);
  const [isOpenResetPassModal, setIsOpenResetPassModal] = useState(false);

  const [isOpenModalSupport, setIsOpenModalSupport] = useState(false);

  const dispatch = useDispatch();
  const errorRes = useSelector(selectError);
  const { isNotAndroid, mobileModel, mobileVendor } = useDetectedDevice();

  const recaptchaRef = useRef(null);
  const { hasCaptcha } = useGetCaptchaConfig();
  const addTail = useAddTail();

  useEffect(() => {
    switch (errorRes) {
      case "WRONG_PASSWORD":
        dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: "Invalid email or password.",
            btnText: "Got it",
          })
        );
        break;
      case "EMAIL_IN_USE":
        dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: "This email is in use, please choose another email for registration or sign in your account",
            btnText: "Got it",
          })
        );
        setModeLogin("login");
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [errorRes]);

  const addUtmTags = () => {
    const utmNetw = getUrlParameter("utm_netw");
    const utmAff = getUrlParameter("utm_aff");

    const years = findAge(
      +valueRegister.bYear.value,
      +valueRegister.bMonth.value,
      +valueRegister.bDay.value
    );
    let genderUtm = valueRegister.looking === "Woman" ? "fem" : "man";
    genderUtm = `${genderUtm}${years >= 45 ? "_45" : "_not_45"}`;

    addTail("utm_gen", genderUtm);
    addTail("utm_real_age", years);

    if (isNotAndroid && years >= 45) addTail("utm_os", "target");

    if (
      !utmNetw &&
      !utmAff &&
      MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID === 1
    )
      addTail("utm_netw", "seosofia");
  };

  const handleRegister = (value, id) => {
    setValueRegister({ ...valueRegister, [id]: value });
  };

  const checkEmailAPI = async (email, hasV) => {
    if (!email?.length) return false;

    const resValidToken = await getRequest(`/api/login/validation`);

    if (resValidToken?.status) {
      return await postRequest(`/api/login/checkEmail`, {
        email: email,
        ...(hasV ? { v_check: +hasV } : {}),
      })
        .then((res) => res)
        .catch(() => {});
    }
  };

  const checkUserEmail = async (valueRegister) => {
    const vCount = localStorage.getItem("v_count") || 0;

    const hasV = await postRequest(
      `/api/v3/click-history/get-lnd-verify-setting`,
      {
        lnd: MIRROR[process.env.REACT_APP_MIRROR_NAME].NAME.toLocaleLowerCase(),
        site_id: MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID,
      }
    )
      .then((res) => res?.status)
      .catch(() => false);

    if (+vCount >= 100) {
      dispatch(
        togleGlobalModal({
          isOpen: "open",
          text: "Please enter real email",
          btnText: "Got it",
        })
      );
      return false;
    }

    const res = await checkEmailAPI(valueRegister?.email, hasV);

    const isEmailInUse = res?.status;
    const isVError = res?.v_error;
    const isVLimitError = res?.v_limit;

    if (isEmailInUse === undefined) {
      dispatch(
        togleGlobalModal({
          isOpen: "open",
          text: "Something went wrong!",
          btnText: "Got it",
        })
      );
      return false;
    } else if (isEmailInUse) {
      dispatch(
        togleGlobalModal({
          isOpen: "open",
          text: "This email is in use, please choose another email for registration or sign in your account",
          btnText: "Got it",
        })
      );

      if (Number(MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID === 4)) {
        dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: "This email is in use, please choose another email for registration or sign in your account",
            btnText: "Got it",
          })
        );
        return false;
      }
      return false;
    }

    if (hasV) {
      if (isVError && !isVLimitError) {
        if (+vCount < 100) {
          localStorage.setItem("v_count", +vCount + 1);
          dispatch(
            togleGlobalModal({
              isOpen: "open",
              text: "Please enter real email.",
              btnText: "Got it",
            })
          );
          return false;
        }
      } else {
        sessionStorage.setItem("is_real_email", "1");
      }
    }

    return true;
  };

  const onSubmitFormRegister = async (e, valueRegister) => {
    if (e) e.preventDefault();

    dispatch(loaderOn());
    if (validateReg()) {
      //CAPCHA
      if (hasCaptcha) {
        try {
          const recaptchaToken = await recaptchaRef.current.executeAsync();
          recaptchaRef.current.reset();

          const checkRecaptchaRes = await postRequest(
            `/api/v3/click-history/verify-captcha-v2`,
            {
              g_recaptcha_response: recaptchaToken,
              site_id: MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID,
              is_development:
                MIRROR[process.env.REACT_APP_MIRROR_NAME].MIRROR ===
                "Development",
            }
          )
            .then((res) => {
              return res;
            })
            .catch((e) => {
              throw e;
            });

          if (!checkRecaptchaRes?.success) {
            dispatch(loaderOff());
            return dispatch(
              togleGlobalModal({
                isOpen: "open",
                text: "Your response to the CAPTCHA appears to be invalid",
                btnText: "Got it",
              })
            );
          }
        } catch (error) {
          dispatch(loaderOff());
          return;
        }
      }

      if (!(await checkUserEmail(valueRegister))) {
        dispatch(loaderOff());
        return;
      }

      addUtmTags();

      dispatch(register(valueRegister)).then((res) => {
        if (res?.payload?.status === false) {
          dispatch(
            togleGlobalModal({
              isOpen: "open",
              text: res?.payload?.message,
              btnText: "Got it",
            })
          );
        } else {
          addTail("registration", "success");
        }
      });
    }
    dispatch(loaderOff());
  };

  const validateReg = (paramLogin) => {
    if (
      !valueRegister.password ||
      !valueRegister.email ||
      (modeLogin === "create" &&
        (!valueRegister.name ||
          !valueRegister.bDay ||
          !valueRegister.bMonth ||
          !valueRegister.bYear ||
          !valueRegister.looking ||
          !valueRegister.policy))
    ) {
      dispatch(
        togleGlobalModal({
          isOpen: "open",
          text: "All fields are required",
          btnText: "Got it",
        })
      );
      setError(true);
      return false;
    } else if (paramLogin !== "login" && valueRegister?.password?.length < 8) {
      dispatch(
        togleGlobalModal({
          isOpen: "open",
          text: "Please enter a password of 8 or more characters",
          btnText: "Got it",
        })
      );
      return false;
    }

    return true;
  };

  const validateFildError = (nameField) => {
    return !valueRegister[nameField];
  };

  const onSubmitFormLogin = (e, valueRegister) => {
    if (e) e.preventDefault();
    if (validateReg("login")) {
      dispatch(logIn(valueRegister)).then((res) => {
        if (res?.payload?.status === false) {
          dispatch(
            togleGlobalModal({
              isOpen: "open",
              text: res?.payload?.message,
              btnText: "Got it",
            })
          );
        }
      });
    }
  };

  const responseGoogle = (response) => {
    if (response?.error) return;

    const deviceData = {
      memory: navigator?.deviceMemory ?? 0,
      height: window?.screen?.height,
      width: window?.screen?.width,
      mobileModel,
      mobileVendor,
      userAgent: window?.navigator?.userAgent,
    };

    let googleData = {
      name: response?.name,
      email: response?.email,
      userID: response?.sub,
      tail: window?.location?.search,
      site_id: MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID,
      month: 1,
      day: 1,
      year: new Date().getFullYear() - 45,
      deviceData,
    };

    dispatch(logInGoogle(googleData));
  };

  const goToRegistaration = () => {
    setModeLogin("create");
    const anchor = document.getElementById("registerForm");
    anchor.scrollIntoView();
  };

  const openPolicy = (namePolicy) => {
    postRequest("/api/page/show", { seo_url: namePolicy }).then((res) => {
      dispatch(
        togleGlobalModal({
          isOpen: "open",
          text: res?.result?.header,
          btnText: "",
          description: (
            <div dangerouslySetInnerHTML={{ __html: res?.result?.content }} />
          ),
        })
      );
    });
  };

  return {
    modeLogin,
    setModeLogin,
    valueRegister,
    handleRegister,
    onSubmitFormRegister,
    onSubmitFormLogin,
    responseGoogle,
    openModalValidate,
    setOpenModalValidate,
    error,
    validateFildError,
    goToRegistaration,
    openPolicy,
    setIsOpenModalSupport,
    isOpenModalSupport,
    isOpenResetPassModal,
    setIsOpenResetPassModal,

    recaptchaRef,
    hasCaptcha,
  };
};

export default useMain;
