// @ts-ignore
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player";
import { useState, useEffect } from "react";
import { AppDispatch } from "redux/store";
import { fetchBalance } from "redux/auth/operations";

import {
  ModalWindowModern,
  Button,
  SendWinkModal,
} from "components/atomsKit/Elements";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import { selectUser } from "redux/auth/selectors";
import { togleGlobalModal } from "redux/interface/slice";
import { changePayStatusContent } from "redux/letters/slice";

import { postRequest } from "utils/requests";

// import cn from "classnames";

// @ts-ignore
import classes from "./ModalContent.module.scss";

interface IProps {
  modalIsOpen?: boolean;
  closeModal?: any;
  items?: any;
  handleChat?: any;
  handleWink?: (wink: any) => void;
  id?: number;
  chat_uid?: string;
  counterPrivatPhoto?: number;
  sayHalloList?: { message_content: string; id: number; site_id: number }[];
  isOpenModalWink?: boolean;
  setIsOpenModalWink?: any;
  handleSayHello?: () => void;
  wink?: number | boolean;
  handleProfile?: any;
}

const ModalContent = ({
  modalIsOpen,
  closeModal,
  items,
  id,
  wink,
  chat_uid,
  handleChat,
  handleWink,
  handleSayHello,
  setIsOpenModalWink,
  isOpenModalWink,
  sayHalloList,
  counterPrivatPhoto,
  handleProfile,
}: IProps) => {
  const [dataModal, setDataModal] = useState([]) as any;

  useEffect(() => {
    setDataModal(items);
  }, [items]);

  const isNotPayed = dataModal[0]?.isNotPayed;

  const userId = useSelector(selectUser)?.user_info?.external_id;

  const dispatch: AppDispatch = useDispatch();

  const handlePhoto = () => {
    postRequest("/api/mailbox/readimage", {
      attachment_id: dataModal[0]?.content?.attachment_id,
      user_id: userId,
    }).then((res: any) => {
      if (res?.status === true) {
        dispatch(fetchBalance());
        setDataModal([
          {
            ...dataModal[0],
            isNotPayed: false,
            original: res?.message_content,
            thumbnail: res?.message_content,
          },
        ]);
        dispatch(
          changePayStatusContent({
            chat_id: dataModal[0]?.chat_id,
            attachment_id: dataModal[0]?.content?.attachment_id,
            link: res?.message_content,
            id: dataModal[0]?.id,
          })
        );
      } else {
        dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: res?.title,
            description: res?.message,
            btnText: "Got it",
          })
        );
        closeModal();
      }
    });
  };

  const renderGalleryItem = (item: any) => {
    return (
      <div className={classes.image}>
        <img src={item.original} alt="" className={classes.img} />
        {!item.free && (
          <div className={classes.textBlurePhoto}>
            <p className={classes.counterText}>
              This member has {counterPrivatPhoto} other private photos
            </p>
            <p className={classes.infoText}>
              To view member’s private photos, start chatting with her
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <SendWinkModal
        config={sayHalloList}
        handleWink={handleWink}
        handleChat={() => {
          handleChat(chat_uid, id);
        }}
        modalIsOpen={isOpenModalWink}
        closeModal={() => {
          setIsOpenModalWink(false);
        }}
        recipient_id={id}
      />
      <ModalWindowModern
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        zIndex={9999}
      >
        <div className={classes.wrap}>
          {dataModal[0]?.type !== "SENT_VIDEO" ? (
            <div
              className={classes.wrapPhoto}
              onClick={isNotPayed ? handlePhoto : undefined}
            >
              {isNotPayed && (
                <p className={classes.textForClick}>Click to view the photo</p>
              )}
              <div className={cn(isNotPayed && classes.blur)}>
                <ImageGallery
                  items={dataModal}
                  showPlayButton={false}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  renderItem={counterPrivatPhoto && renderGalleryItem}
                />
              </div>
              {(handleChat || handleSayHello) && (
                <div className={classes.panelBtn}>
                  <Button
                    onClick={() => handleChat(chat_uid, id)}
                    padding="0 20px"
                    fitContent
                    icon="chat"
                    text="Start chatting"
                    margin="0 10px 0 0"
                  />
                  {wink !== 1 ? (
                    <Button
                      padding="0 20px"
                      fitContent
                      margin="0 10px 0 0"
                      iconSvg="say-hello-yellow"
                      isWhite
                      text="Say Hello"
                      onClick={() => setIsOpenModalWink(true)}
                    />
                  ) : (
                    <Button
                      padding="0 20px"
                      fitContent
                      margin="0 10px 0 0"
                      iconSvg="userMenu"
                      isWhite
                      bgImg
                      classNameIcon="btnIco"
                      text="View profile"
                      onClick={() => handleProfile(id)}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <ReactPlayer
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              onContextMenu={(e: any) => e.preventDefault()}
              url={dataModal[0]?.original}
              playing
              controls
              width={window.innerWidth - 40}
              height={window.innerHeight - 40}
            />
          )}
        </div>
      </ModalWindowModern>
    </>
  );
};

export default ModalContent;
