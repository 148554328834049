import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import * as Sentry from "@sentry/react";

import { MIRROR } from "data/mirrorConfig";

import * as USER from "data/user";

import {
  useAuth,
  useAccessKeyRedirect,
  useInitUserBirthdayModal,
  useInitReristerOneStepModal,
  useFBConversion,
  useGoogleDataLayer,
  useInitPushNotification,
  useHandleRouting,
  useAwinConversion,
  useTransactionConfig,
  useInitUtmSpend,
} from "hooks";

import {
  refreshUser,
  getUserInfo,
  getMyProfile,
  fetchFaqAndTutorial,
  fetchRoles,
  fetchTail,
  fetchIsFBConversion,
} from "redux/auth/operations";
import {
  chatCounter,
  fetchTransaction,
  fetchSayHalloList,
  fetchCheckNotify,
} from "redux/interface/operations";

import { delTransaction, delNotification } from "redux/interface/slice";

import {
  getIsOpenFilter,
  getIsOpenAlert,
  getGlobalModalInfo,
  getNavInfo,
  getModalContent,
  getTransaction,
  getNotification,
} from "redux/interface/selectors";

import { selectUser, selectTail } from "redux/auth/selectors";

import {
  clickAlert,
  clickFilter,
  togleGlobalModal,
  togleModalContent,
  setDataModalContent,
} from "redux/interface/slice";
import { postRequest } from "utils/requests";
import { generateUTMCDate } from "utils/common";
import { initPosthog } from "utils/posthog";

import { diffAfficeByRegisterDate, sendAfficeFromStore } from "utils/affise";
import { diffBinomoByRegisterDate, sendBinomoFromStore } from "utils/binomo";

const useLayout = () => {
  const [isOpenBanerConfirm, setIsOpenBanerConfirm] = useState(false);
  const [isOpenBanerWelcome, setIsOpenBanerWelcome] = useState(false);

  const dispatch = useDispatch();
  const { isRefreshing, isLoggedIn } = useAuth();
  const handleRouting = useHandleRouting();

  const isOpenFilter = useSelector(getIsOpenFilter);
  const isOpenAlert = useSelector(getIsOpenAlert);
  const globalModalInfo = useSelector(getGlobalModalInfo);
  const modalContent = useSelector(getModalContent);

  const userInfo = useSelector(selectUser)?.user_info;
  const roles = useSelector(selectUser)?.userRoles;

  const userExternalId = useSelector(selectUser)?.user_info?.external_id;
  const navInfo = useSelector(getNavInfo);
  const tail = useSelector(selectTail);

  const location = useLocation();

  const transactions = useSelector(getTransaction);
  const notifications = useSelector(getNotification);

  const {
    sentFBAuth,
    sentFBAuth55,
    sentFBDelfinConversion,
    sentFBConfirmEmailConversion,
  } = useFBConversion();

  const {
    pushEventDolphin,
    pushEventLead,
    pushEventRegistration,
    pushEventUserId,
    pushEventTarget45Event,
    pushEventAndroid45Event,
    pushEventIos45Event,
    pushEventWin45Event,
    pushEventMac45Event,
    pushEventConfirmedEmail,
    pushEventSecondVisit,
  } = useGoogleDataLayer();

  useInitUtmSpend();

  const transactionConfig = useTransactionConfig();

  const { sendAwinReg } = useAwinConversion();

  const initPushNotification = useInitPushNotification();

  const {
    isUserBirthdayModal,
    closeUserBirthdayModal,
    checkUserBirthday,
    birthdayBonusCredits,
  } = useInitUserBirthdayModal();

  const { isOpenOneStepModal, setIsOpenOneStepModal } =
    useInitReristerOneStepModal();

  const navigate = useNavigate();

  const { accessKeyRedirect } = useAccessKeyRedirect();

  useEffect(() => {
    const modalRoot = document.getElementById("modal-root");
    if (!modalRoot) {
      const newModalRoot = document.createElement("div");
      newModalRoot.setAttribute("id", "modal-root");
      document.body.appendChild(newModalRoot);
    }
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    dispatch(refreshUser());
    accessKeyRedirect();

    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    setIsOpenBanerConfirm(
      userExternalId &&
        userInfo?.email_confirmed === 0 &&
        localStorage.getItem("modalConfirmClose") !== "1"
        ? true
        : false
    );

    if (MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID !== 4)
      checkUserBirthday();

    if (window?.location?.search?.includes("lnd_one_step")) {
      setIsOpenOneStepModal(true);
    }

    // eslint-disable-next-line
  }, [userExternalId, localStorage.getItem("modalWelcome")]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserInfo());
      dispatch(getMyProfile());
      dispatch(fetchRoles());
      dispatch(fetchTail());
      dispatch(fetchIsFBConversion());
      dispatch(fetchFaqAndTutorial());
      dispatch(fetchSayHalloList());
      dispatch(fetchCheckNotify());

      setTimeout(() => dispatch(fetchTransaction()), 3000);
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    if (userInfo?.external_id) {
      handleRouting.init();
    }
    // eslint-disable-next-line
  }, [userInfo?.external_id]);

  /// for Analitic
  useEffect(() => {
    if (userInfo?.external_id) {
      // after userInfo
      if (Sentry && typeof Sentry?.setUser === "function") {
        Sentry.setUser({ id: userInfo?.external_id });
      }

      diffBinomoByRegisterDate(
        userInfo,
        tail?.response?.tail,
        tail?.response?.clickid,
        tail?.utm_aff_hash
      );
      diffAfficeByRegisterDate(userInfo);
      initPosthog(userInfo?.external_id, userInfo?.name);
      // Push DataLayer for FB
      pushEventSecondVisit();

      if (!+userInfo.ghost) {
        transactionConfig.fetchTransactionConfig();
      }
      if (!+userInfo?.ghost) {
        initPushNotification.lastActivity();
      }

      if (location?.search?.includes("utm_netw=onesg"))
        initPushNotification.checkPush();
      if (location?.search?.includes("utm_netw=adrl"))
        initPushNotification.checkAdrol();
      if (location?.search?.includes("utm_netw=tbl"))
        initPushNotification.postCheckTbl();
      if (location?.search?.includes("utm_source=adroll_email"))
        initPushNotification.checkEmailAdrol();
      if (location?.search?.includes("utm_source=sndgrd_email"))
        initPushNotification.checkSendgrig();
      if (location?.search?.includes("utm_source=center_email"))
        initPushNotification.checkCenterEmail();
      if (
        location?.search?.includes("utm_netw=sofia_ggl_gdn_nik") ||
        location?.search?.includes("utm_netw=myspec_ggl_gdn_nik") ||
        location?.search?.includes("utm_netw=lfh_ggl_gdn_nik")
      ) {
        initPushNotification.postCheckGoogle();
      }

      if (+userInfo?.payment_total >= USER.PAYMENT_TOTAL_TO_AFFISE_SPEND_200) {
        sendBinomoFromStore({
          externalID: userInfo?.external_id,
          siteID: userInfo?.site_id,
          status: USER.BINOMO_SPEND_200,
          clickid: tail?.response?.clickid,
          utm_aff_hash: tail?.utm_aff_hash,
          ghost: userInfo?.ghost,
          tail: tail?.response?.tail,
        });
        sendAfficeFromStore({
          status: USER.AFFISE_SPEND_200,
          info: userInfo,
        });
      }

      if (location?.search?.includes("utm_confirm=true")) {
        postRequest("/api/marketing/checkMarketingConversion", {
          eventName: "confirmed_email",
        }).then((resp) => {
          if (resp?.status && resp?.response?.check) {
            // Push DataLayer for FB
            pushEventConfirmedEmail();

            if (tail?.response?.tail?.includes("utm_age=45")) {
              sentFBConfirmEmailConversion();
            }
          }
        });
      }

      ////// after registration
      if (window?.location?.search?.includes("registration=success")) {
        localStorage.setItem(
          `rd-${userInfo?.external_id}`,
          JSON.stringify(new Date())
        );

        if (!location.search.includes("utm_cdate")) {
          navigate(
            `${location.pathname}${location.search}&${generateUTMCDate()}`,
            { replace: true }
          );
        }

        setIsOpenBanerWelcome(true);

        sendAwinReg();
        // Push DataLayer for FB
        pushEventLead();
        pushEventUserId();
        pushEventTarget45Event();
        pushEventAndroid45Event();
        pushEventIos45Event();
        pushEventWin45Event();
        pushEventMac45Event();

        if (userInfo?.age >= 35) {
          // This check can be used for other events in the future
          postRequest("/api/marketing/checkMarketingConversion", {
            eventName: "registration",
          }).then((res) => {
            if (res?.status && res?.response?.check) {
              sentFBAuth();
            }
          });
        }

        if (tail?.response?.tail?.includes("utm_age=45")) {
          pushEventRegistration();
        }

        if (tail?.response?.tail?.includes("utm_55age=55")) {
          sentFBAuth55();
        }

        sendBinomoFromStore({
          externalID: userInfo?.external_id,
          siteID: userInfo?.site_id,
          status: USER.BINOMO_SIGNUP,
          clickid: tail?.response?.clickid,
          utm_aff_hash: tail?.utm_aff_hash,
          tail: tail?.response?.tail,
          ghost: userInfo?.ghost,
        });

        if (+userInfo.age > 30) {
          setTimeout(() => {
            sendBinomoFromStore({
              externalID: userInfo?.external_id,
              siteID: userInfo?.site_id,
              status: USER.BINOMO_UPLOAD_AVATAR,
              clickid: tail?.response?.clickid,
              utm_aff_hash: tail?.utm_aff_hash,
              ghost: userInfo?.ghost,
            });
          }, 1000 * 5);
        }
      }
    }
    // eslint-disable-next-line
  }, [userInfo?.external_id, tail]);

  useEffect(() => {
    try {
      let id = 0;

      if (+roles?.heightRole) id = 3;
      else if (+roles?.maybeHeight) id = 2;
      else if (+userInfo?.payment_total >= 2000) id = 1;

      localStorage.setItem("X-Request-ID", id?.toString());

      const dolphinStore = localStorage.getItem("dolphin");

      if (!!+roles?.maybeHeight && !dolphinStore) {
        localStorage.setItem("dolphin", "1");

        postRequest("/api/marketing/checkMarketingConversion", {
          eventName: "dolphin",
        }).then((res) => {
          if (res?.status && res?.response?.check) {
            if (tail?.response?.tail?.includes("utm_age=45")) {
              sentFBDelfinConversion();
            }
            // Push DataLayer for FB
            pushEventDolphin();
          }
        });
      }
    } catch (err) {}
    // eslint-disable-next-line
  }, [userInfo?.payment_total, roles]);

  useEffect(() => {
    if (userExternalId) {
      dispatch(chatCounter(userExternalId));
    }
    // eslint-disable-next-line
  }, [userExternalId]);

  const handleCloseGlobalModal = () => {
    dispatch(togleGlobalModal({ isOpen: "close" }));
  };

  const handleFilter = (mode) => {
    if (mode === "close") {
      dispatch(clickFilter("close"));
    } else {
      dispatch(clickFilter("toggle"));
    }
  };

  const handleAlert = () => {
    dispatch(clickAlert("toggle"));
  };

  const handleCloseAlert = () => {
    dispatch(clickAlert("close"));
  };

  const handleCloseModalContent = () => {
    dispatch(togleModalContent(false));
    dispatch(setDataModalContent([]));
  };

  const handleDelTransaction = (id) => {
    handleDelNotification(id);
  };

  const handleDelNotification = (id) => {
    dispatch(delNotification(id));
    dispatch(delTransaction(id));
  };

  const handleTransaction = (chat_uid, id, isMail, idTransaction) => {
    dispatch(delNotification(idTransaction));
    dispatch(delTransaction(idTransaction));
    if (isMail) {
      navigate(`/letter?profileId=${id}`);
    } else if (!chat_uid) {
      navigate(`/profile-woman/${id}`);
    } else {
      navigate(`/chat/${chat_uid}?profile=${id}`);
    }
  };

  return {
    isRefreshing,
    isLoggedIn,
    handleCloseGlobalModal,
    handleFilter,
    isOpenFilter,
    handleAlert,
    isOpenAlert,
    navInfo,
    handleCloseAlert,
    userInfo,

    globalModalInfo,
    handleCloseModalContent,
    modalContent,
    transactions,
    notifications,
    handleDelTransaction,
    handleDelNotification,
    handleTransaction,
    isOpenBanerConfirm,
    setIsOpenBanerConfirm,
    isOpenBanerWelcome,
    setIsOpenBanerWelcome,

    isUserBirthdayModal,
    closeUserBirthdayModal,
    checkUserBirthday,
    birthdayBonusCredits,

    isOpenOneStepModal,
    setIsOpenOneStepModal,
  };
};

export default useLayout;
