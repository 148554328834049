import { configureStore } from "@reduxjs/toolkit";
import { girlsListReducer } from "./girlsList/slice";
import { interfaceReducer } from "./interface/slice";
import { authReducer } from './auth/slice';
import { chatReducer } from "./chat/slice";
import {lettersReducer} from './letters/slice'

export const store = configureStore({
  reducer: {
    girlsList: girlsListReducer,
    interface: interfaceReducer,
    auth: authReducer,
    chat: chatReducer,
    letters: lettersReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

